import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { useDispatch, useSelector } from 'react-redux'
import { profileSelectors, profileThunks } from '@ucheba/store/profile'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ELoading } from '@ucheba/store/utils/response/types'
import { useDialog } from '@ucheba/ui/components/Dialog/bll'
import { useRouter } from 'next/router'
import { stringifyUrl } from '@ucheba/utils/helpers/router'
import { IUseParentFormDialog, IUseParentFormDialogRequest } from './types'
import {
  parentForm,
  parentFormDialogRequestId,
  parentFormDialogSuccessId,
  uchebaForm,
  institutionForm,
  studentFormText,
  parentFormText,
} from './constants'

export const useParentFormDialog: IUseParentFormDialog = (props) => {
  const { exam } = props || {}
  const { id } = props || {}

  const profile = useSelector(profileSelectors.entity)
  const parentFormDialogRequest = useDialog(parentFormDialogRequestId + id)
  const parentFormDialogSuccess = useDialog(parentFormDialogSuccessId + id)
  const { asPath, replace, query } = useRouter()

  const isParent = useMemo(() => {
    if (profile) {
      return profile?.isParent
    }

    return false
  }, [profile])

  useEffect(() => {
    if (profile && !profile.secondPhone && query[parentForm] === String(id) && !exam) {
      setTimeout(() => {
        parentFormDialogRequest.openDialog()
      }, 600)
    }

    if (profile && query[institutionForm] === String(id) && !exam) {
      setTimeout(() => {
        parentFormDialogSuccess.openDialog()
      }, 600)
    }
  }, [isParent, profile])

  useEffect(() => {
    if (
      (parentFormDialogRequest.isActive && query[parentForm]) ||
      (parentFormDialogSuccess.isActive && query[uchebaForm])
    ) {
      replace(
        stringifyUrl(asPath, { set: { ...query }, exclude: [parentForm, uchebaForm] }),
        undefined,
        { shallow: true }
      )
    }
  }, [parentFormDialogRequest.isActive, parentFormDialogSuccess.isActive])

  return {
    parentFormDialogRequest,
    parentFormDialogSuccess,
  }
}

export const useParentFormDialogRequest: IUseParentFormDialogRequest = (props) => {
  const { messengersDialog, onClose, id, exam } = props
  const dispatch = useDispatch()
  const profile = useSelector(profileSelectors.entity)
  const profileLoading = useSelector(profileSelectors.loading)

  const { query } = useRouter()

  const isLoading = useMemo(() => {
    return profileLoading === ELoading.pending
  }, [profileLoading])

  const initialValues = useMemo(() => {
    return {
      [EDataKeys.isParent]:
        // eslint-disable-next-line no-nested-ternary
        profile?.isParent === null ? 'false' : profile?.isParent ? 'true' : 'false',
      [EDataKeys.parentName]: profile?.secondName || '',
      [EDataKeys.parentPhone]: profile?.secondPhone || '',
    }
  }, [profile?.secondName, profile?.secondPhone, profile?.isParent])

  const [isCorrectPhone, setIsCorrectPhone] = useState(false)

  const onSubmit = useCallback(
    async (values: Record<string, any>): void => {
      const isParent = values[EDataKeys.isParent] === 'true'
      const secondName = values[EDataKeys.parentName]
      const secondPhone = values[EDataKeys.parentPhone]

      const res = await dispatch<any>(
        profileThunks.patch({
          data: {
            isParent,
            secondName,
            secondPhone,
          },
        })
      )

      const { error } = res
      if (error) {
        setIsCorrectPhone(true)
      } else {
        await dispatch(profileThunks.get({}))

        onClose()

        setTimeout(() => {
          messengersDialog.openDialog()
        }, 600)
      }
    },
    [dispatch, onClose, query, id, exam, messengersDialog]
  )

  const isStudent = useMemo(() => {
    return profile?.isParent === null
  }, [profile])

  const formText = useMemo(() => {
    return profile?.isParent ? parentFormText : studentFormText
  }, [profile])

  return {
    isLoading,
    initialValues,
    onSubmit,
    isStudent,
    formText,
    isCorrectPhone,
  }
}
