import React, { FC, memo } from 'react'
import { Button } from '@ucheba/ui/components/Button/desktop'
import PlayIcon from '@ucheba/ui/icons/icon__circled-play.svg'
import { EEventRegisteredStatus, IEventsListItemButtonProps } from '../types'

/** Кнопка для элемента списка мероприятий */
const EventsListItemButton: FC<IEventsListItemButtonProps> = (props) => {
  const { children, onClick, registeredStatus, color = 'blue', progress, href } = props

  return (
    <Button
      href={registeredStatus === EEventRegisteredStatus.watchBroadcast ? href : undefined}
      StartIcon={
        registeredStatus === EEventRegisteredStatus.watchBroadcast &&
        href && <PlayIcon width='20px' height='20px' />
      }
      progress={progress}
      color={color}
      onClick={onClick}
      target='_blank'
    >
      {children}
    </Button>
  )
}

export { EventsListItemButton }
export default memo(EventsListItemButton)
