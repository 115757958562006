import { EEventsDataKeys, EEventsFacets } from '@ucheba/store/events/search/types'

export const successRegisterOfflineEventNoticeId = 'successRegisterOfflineEventNoticeId'
export const successRegisterOnlineNotStartedEventNoticeId =
  'successRegisterOnlineNotStartedEventNoticeId'
export const successRegisterOnlineStartedEventNoticeId =
  'successRegisterOnlineStartedEventNoticeId'

export const eventsFacetBySearchQuery = {
  [EEventsDataKeys.tagIds]: EEventsFacets.tags,
  [EEventsDataKeys.locationIds]: EEventsFacets.locations,
}

export const searchQueryByEventsFacet = {
  [EEventsFacets.tags]: EEventsDataKeys.tagIds,
  [EEventsFacets.locations]: EEventsDataKeys.locationIds,
}

export const titleByEventsFacetName = {
  [EEventsFacets.tags]: 'Рубрика',
  [EEventsFacets.locations]: 'Город',
}
