export interface IRegDialog {
  open: boolean
  onClose: () => void
  params: Record<string, string | number>
  whatsAppReg?: boolean
  queue?: EQueueElements[]
  title?: string
  description?: string
}

export enum EQueueElements {
  tg = 'tg',
  wa = 'wa',
  vk = 'vk',
}
