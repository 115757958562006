import styled, { StyledProps } from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'

interface IBlock {
  isFinished?: boolean
  isDivider?: boolean
}

const Block = styled.div<StyledProps<IBlock>>`
  border-top: 1px solid ${sv.color.gray9};
  position: relative;

  :before {
    content: 'Записи прошедших вебинаров';
    text-align: center;
    width: 275px;
    position: absolute;
    top: -12px;
    left: calc(50% - 137.5px);
    font-weight: ${sv.fontWeight.semibold};
    color: ${sv.color.gray60};
    font-size: ${sv.fontSize.large};
    background-color: #fff;
    display: ${({ isFinished }) => (isFinished ? 'block' : 'none')};
  }
`
export default { Block }
